import React from "react";
import PropTypes from "prop-types";
import "./layout.css";

const Layout = ({ children }) => {
  return (
    <main className="relative z-0">{children}</main>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;

