import React from "react";
import get from "lodash/get";
import StateLocations from "./StateLocations";

class WaLocations extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locations: get(this, 'props.data.allContentfulLocation.nodes'),
    }
  }

  render() {
    return (
      <StateLocations locations={this.state.locations} currentState="WA"/>
    )
  }
}

export default WaLocations

export const pageQuery = graphql`
query {
  allContentfulLocation(filter:{state: {eq:"WA"}}){
    nodes {
      id
      name
      address {
        lat
        lon
      }
      streetAddress
      suburb
      state
      postcode
      webstore
      phone
    }
  }
}`
