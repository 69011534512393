import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";
import socialBanner from "@images/social/waivpay.jpg";

function SEO({ title, description, image, article, lang }) {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            defaultTitle: title
            titleTemplate
            defaultDescription: description
            siteUrl: url
            defaultImage: image
            linkedinUrl
            author
            twitterUsername
          }
        }
      }
    `
  );

  const {
    defaultTitle,
    titleTemplate,
    defaultDescription,
    siteUrl,
    // linkedinUrl,
    // author,
    twitterUsername,
  } = site.siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${socialBanner}`,
    url: `${siteUrl}${pathname}`,
  }

  return (
    <Helmet
      title={seo.title}
      titleTemplate={titleTemplate}
      htmlAttributes={{ lang }}
    >
      <link rel="canonical" href={seo.url}/>
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />}

      {seo.url && <meta property="og:url" content={seo.url} />}
      <meta property="og:site_name" content="WAIVPAY"/>
      {seo.title && <meta property="og:title" content={seo.title} />}
      <meta property="og:type" content={!!article ? "article" : "website"} />
      {seo.description && <meta property="og:description" content={seo.description} />}
      <meta property="og:image" content={seo.image} />}

      <meta name="twitter:card" content="summary_large_image" />
      {twitterUsername && <meta name="twitter:creator" content={twitterUsername} />}
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && <meta name="twitter:description" content={seo.description} />}
      <meta name="twitter:image" content={seo.image} />}
    </Helmet>
  );
}

SEO.defaultProps = {
  title: '',
  description: '',
  image: null,
  lang: `en`,
}

SEO.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  image: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
}

export default SEO;

